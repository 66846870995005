<template>
    <div>
        <load-screen-component></load-screen-component>
        <page-header page-title="Assinaturas" filter @filter="setModalFilter"
                     secondIconBtnNewLink="icon-show_chart"
                     secondBtnLink="Dashboard" @secondActionLink="$router.push({name:'DashboardAssing'})"
                     ></page-header>
        <div class=" p-1 mb-1">
            <div class="row mw-100">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 align-self-center">
                    <div class="tags-block mt-1 ml-2 row left-content">
                        <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                             @click="$router.push({name: 'IndexAssignPending'})">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':'#fb7f12'}"></i>
                                {{ this.t('ASSIGN.WAITING') }}
                            </div>
                        </div>
                        <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                             @click="$router.push({name: 'IndexAssignPendingAdm'})">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':'#fb7f12'}"></i>
                                Pendente P/ Administração
                            </div>
                        </div>
                        <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                             @click="$router.push({name: 'IndexAssignFinalized'})">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':'#3FBF3F'}"></i>
                                {{ this.t('ASSIGN.FINALIZED') }}
                            </div>
                        </div>
                        <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                             @click="$router.push({name: 'IndexAssignCanceled'})">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color': '#f34646'}"></i>
                                {{ this.t('ASSIGN.CANCELED') }}
                            </div>
                        </div>
                        <div class="tags pointer card  pl-2 pr-2 pt-1 pb-1 mb-1 mr-2"
                             @click="$router.push({name: 'IndexAssignExpired'})">
                            <div class="mr-2">
                                <i class="icon-label" :style="{'color':'#e0bb47'}"></i>
                                {{ this.t('ASSIGN.EXPIRED') }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2 text-right pr-0" v-if="false">
                    <button data-cy="lot_index_dropdown" class="btn btn-custom bg-btn no-border"
                            type="button"
                            id="import-buttom"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="icon-menu"/>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right dropdown-black dropdown-lot w-auto"
                         id="dropdown-import">
                        <div class="dropdown-item bg-hover-black w-auto">
                            <a class="row item-custom2 ml-1 text-white pr-2">
                                <i class="icon-download mr-1 font-15"/>
                                Exportar em XLSX
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-1" v-if="hastwoAllotments">
            <div class="card-body pt-2 row">
                <div class="col-3">
                    <label>{{ t('NAV.ALLOTMENT') }}</label>
                    <Select2 :settings="{ width: '100%', placeholder:  t('LOTS.ALLOTMENT')}"
                             :options="[{id: 'all', text: 'Todos'}, ...allotments]"
                             v-model="allotmentId" @select="changeAllotment($event)"/>
                </div>
                <div class="col-3">
                    <label>Documentos Criados em:</label>
                    <Select2 :settings="{ width: '100%'}" :options="[
                                {id: '7', text: 'Últimos 07 Dias'},
                                {id: '15', text: 'Últimos 15 Dias'},
                                {id: '30', text: 'Últimos 30 Dias'},
                                {id: '60', text: 'Últimos 60 Dias'},
                                {id: '90', text: 'Últimos 90 Dias'},
                                {id: '120', text: 'Últimos 120 Dias'},
                                {id: 'all', text: 'Todos'},
                                ]" @select="index()" v-model="filter.last_days" placeholder="selecione"
                    />
                </div>
            </div>
        </div>
        <listComponent v-if="items?.data?.length" :items="items?.data" @open="open($event)" :haveDetails="true">
            <template v-slot:principal-info="{items}">
                <div class="list-primary hide-text pt-0 pb-0">
                    <div>{{ items.iten.name }}</div>
                    <div class="d-inline-flex">
                        <div class=" card rounded-pill pt-1 pb-1 m-0 mr-2 pl-2 pr-2 font-weight-bold text-center"
                             style="width: 115px;"
                             :style="{'background-color': getColorStatusAssign(items.iten) ,'color': 'white'}">
                            {{ translateStatusAssign(items.iten) }}
                        </div>
                        <div class="signature-quantity m-0">{{ signaturesQuantity(items.iten) }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:date="items">
                <div style="text-align: center">
                    <label>{{ t('ASSIGN.DEADLINE') }}:</label>
                    <div>{{ formatDate(items.iten.deadline_at) }}</div>
                </div>
            </template>
            <template v-slot:dropdown-options="items">
                <div class="dropdown-item pointer text-white"
                     @click="showDocumentOrigimal(items.iten.id)">
                    <i class="icon-eye1 mr-2 font-15"/>{{ t('ASSIGN.VIEW_DOC_ORIGINAL') }}
                </div>
                <div v-if="items.iten.status === 'closed'" class="dropdown-item pointer text-white"
                     @click="showDocumentSigned(items.iten)">
                    <i class="icon-eye mr-2 font-15"/>{{ t('PROPOSAL.VIEW_DOC_ALREADY_SIGN') }}
                </div>
                <div class="dropdown-item pointer text-white" v-if="getSignersNotSigned(items.iten) && items.iten.status === 'running'"
                     @click="notifyAllPendin(items.iten)">
                    <i class="icon-insert_comment mr-2 font-15"/>{{ t('ASSIGN.NOTIFY_PENDING') }}
                </div>
            </template>
            <template v-slot:details-itens="items">
                <div class="form-group mt-2" v-for="signer in items.iten.signers" v-bind:key="signer.id">
                    <div>
                        <div>
                            <div v-if="signer.signed_at === null && (items.iten.status === 'running')"
                                 class="whats pointer mt-3">
                                <div v-if="signer.signed_at === null"
                                     class="whats pointer mr-4 btn-primary">
                                    <a :data-flow="'left'" :data-tooltip="'Copiar Link de Solicitação de Assinatura'"
                                       @click="copyToClipboard(signer.request_signature_key)">
                                        <i class="icon-copy" style="color: #0a3a75"></i>
                                    </a>
                                </div>
                                <div style="inset-inline: auto" class="ml-4">
                                    <a :data-flow="'left'" :data-tooltip="'Solicitação de assinatura via whatsapp'"
                                       @click="sendWhatsapp(signer)">
                                        <img src="/img/whatsapp.png">
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div class="list-primary hide-text" style="display: inline-block">
                <span :data-tooltip=translate(signer.status_of_signature) data-flow="bottom">
                  <div id="statusSigner" :class="selectClass(signer.status_of_signature)"></div>
                </span>
                            <div class="name-signer">{{ signer.name }}</div>
                            <div class="seller" id="sing_as"> {{ translate(signer.sign_as) }}
                            </div>
                        </div>

                        <div v-if="signer.signed_at !== null" class="date">
                            <label>Data da Assinatura:</label>
                            <div>{{ formatDate(signer.signed_at) }}</div>
                        </div>
                    </div>
                </div>
            </template>
        </listComponent>
        <div class="card" v-if="!items?.data?.length">
            <div class="card-header"></div>
            <div class="card-body pt-0">
                <div class="text-center">
                    <h5>{{ t('ASSIGN.NONE_FOUND') }}</h5>
                </div>
            </div>
        </div>
        <div class="row gutters">
            <div class="col-2" v-if="!widthMd(1000)"></div>
            <div :class="!widthMd(1000) ? 'col-8' : 'col-12'">
                <pagination-component v-if="items?.data?.length" :items="items" :to="'/pendentes-pela-administacao'"
                                      @changePage="index($event)">
                </pagination-component>
            </div>
            <div class="col-2" v-if="!widthMd(1000)">
                <div class="row gutters d-flex justify-content-end" v-if="items && items.data && items.data[0]">
                    <div class="col-8">
                        <label>Por página: </label>
                        <Select2 :settings="{width: '100%'}" placeholder="Items por Página" @select="changePaginate"
                                 :options="paginate"
                                 id="paginate" name="paginate"
                                 v-model="perPage"
                        ></Select2>
                    </div>
                </div>
            </div>
        </div>
        <modal :title-modal="modalTitle" modal-large>
            <template v-slot:title>{{modalTitle}}</template>
            <filterModalAssign v-if="modalChoose === 1" @filtering="filtering" @clearFilter="clearFilter"></filterModalAssign>
        </modal>
    </div>
</template>

<script>
import pageHeader from '../../components/layouts/pageHeader';
import loadScreenComponent from '../../components/layouts/loadScreenComponent';
import Proposal from '../../services/Proposal';
import listComponent from '../proposal/listComponent';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import indexClickSign from '../../mixins/indexClickSign';
import Select2 from 'vue3-select2-component';
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {mapState} from 'vuex';
import widthMd from '../../mixins/widthMd';
import modal from '../../components/modal';
import filterModalAssign from './filterModalAssign';
import permission from '../../components/permission';

export default {
    name: "indexAllAssign",
    components: {
        pageHeader,
        listComponent,
        PaginationComponent,
        loadScreenComponent,
        Select2,
        modal,
        filterModalAssign,
    },
    mixins: [indexClickSign,widthMd,permission],
    mounted() {
        if (!this.validatePermissions('ConfigClickSign:index')) {
            this.$router.push({name: 'Home'});
            return;
        }
        if(this.$route.params.allotmentId){
            this.filter.allotment_id = this.$route.params.allotmentId;
            this.allotmentId = this.$route.params.allotmentId;
        }else{
            this.filter.allotment_id = 'all';
        }
        this.index()
    },
    data() {
        return {
            modalChoose: null,
            modalTitle: '',
            filter: {last_days: 'all'},
            perPage: null,
            term: '',
            allotmentId: null,
            items: {data: []},
        }
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },
    computed: {
        ...mapState({
            paginate: 'paginate',
            hastwoAllotments: 'hastwoAllotments',
            allotments: 'allotments',
            hasConfigClickSign: 'hasConfigClickSign'
        })
    },
    methods: {
        filtering(filter){
            this.filter = filter;
            this.index();
            document.getElementById('closeX')?.click();
        },
        clearFilter(){
            this.filter = {last_days: 'all'};
            if(this.$route.params.allotmentId){
                this.filter.allotment_id = this.$route.params.allotmentId;
                this.allotmentId = this.$route.params.allotmentId;
            }else{
                this.filter.allotment_id = 'all';
            }
            this.index();
            document.getElementById('closeX')?.click();
        },
        setModalFilter(){
            this.modalChoose = 1;
            this.modalTitle = 'Filtro de Assinaturas';
        },
        changePaginate() {
            this.index()
        },
        search(term) {
            this.term = term;
            this.index()
        },
        changeAllotment({id}) {
            this.allotmentId = id;
            this.filter.allotment_id = id;
            this.index()
        },
        index(page = 1) {
            this.$store.commit('changeLoading', true);
            Proposal.indexAssigns('all', page, this.allotmentId, this.term, this.perPage, this.filter).then(resp => {
                this.items = resp.data;
                this.$store.commit('changeLoading', false);
            }).catch(() => {
                this.$store.commit('changeLoading', false);
            })
        },
        translateStatusAssign(iten) {
            switch (iten.status) {
                case 'canceled':
                    return this.t('ASSIGN.CANCELED');
                case 'running':
                    return this.t('ASSIGN.WAITING');
                case 'expired':
                    return this.t('ASSIGN.EXPIRED');
                case 'closed':
                    return this.t('ASSIGN.FINALIZED');
            }
        },
        getColorStatusAssign(iten) {
            switch (iten.status) {
                case 'canceled':
                    return '#f34646';
                case 'running':
                    return '#fb7f12';
                case 'expired':
                    return '#e0bb47';
                case 'closed':
                    return '#3FBF3F';
            }
        }
    }
}
</script>

<style scoped>
.signer-signed {
    display: inline-block;
    background-color: #3FBF3F;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-running {
    display: inline-block;
    background-color: #fb7f12;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-canceled {
    display: inline-block;
    background-color: #f34646;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.date {
    display: inline-block;
    margin-left: 45%;
}

.whats {
    display: inline-block;
    margin-left: 45%;
    float: right;
    font-size: 15px;
}

.seller {
    margin-left: 24px;
    font-size: 12px;
    color: #5f5f5f;
}

.signature-quantity {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 3px;
    color: #3a424c;
}

.running {
    background-color: #fb7f12;
    width: 80px;
    font-size: 11px;
    color: #EBF1EB;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.name-signer {
    display: inline-block;
    width: 500px;
    margin-top: -3px;
    position: relative;
    margin-left: 23px;
}

.btn-background {
    width: 100%;
    font-size: large !important;
    font-weight: 600 !important;
    background-color: #adadad !important;
}

.swal2-container {
    z-index: 100000000000;
}

[data-tooltip] {
    position: absolute;
    cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
    line-height: 1;
    font-size: .9em;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
}

[data-tooltip]:before {
    content: "";
    border: 5px solid transparent;
    z-index: 100;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 8px;
    border-radius: 3px;
    background: #2B2525;
    color: #F5F5F5;
    z-index: 99;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #2B2525;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
    bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before, [tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, -4px);
    transform: translate(-50%, -4px);
}

[data-tooltip][data-flow="bottom"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #0F0738;
}

[data-tooltip][data-flow="bottom"]::after {
    top: calc(100% + 5px);
}

[data-tooltip][data-flow="bottom"]::before, [data-tooltip][data-flow="bottom"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, 8px);
    transform: translate(-50%, 8px);
}

[data-tooltip][data-flow="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #0F0738;
    left: calc(0em - 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #0F0738;
    right: calc(0em - 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip][data-flow="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip=""]::after, [data-tooltip=""]::before {
    display: none !important;
}
</style>